<template>
  <div class="equipment">
    <BlackBanner />
    <div class="header">
      <div class="wrap">
        <div class="image">
          <img src="@/assets/images/equipment/phone.png" />
        </div>
        <p>K-CHECK IS A COMPLETE DEVICE CERTIFICAITON SOLUTION.</p>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div class="lock">
          <div class="image">
            <img src="@/assets/images/equipment/equip1.png" />
          </div>
          <p>Lock detection</p>
        </div>

        <div class="lock">
          <div class="image">
            <img src="@/assets/images/equipment/equip2.png" />
          </div>
          <p class="diagnose">Diagnose</p>
          <p class="disabled">Automatic component function scanning</p>
        </div>

        <div class="lock">
          <div class="image">
            <img src="@/assets/images/equipment/equip3.png" />
          </div>
          <p>Battery health</p>
        </div>
      </div>
      <div class="bottom">
        <div class="lock">
          <div class="image">
            <img src="@/assets/images/equipment/equip4.png" />
          </div>
          <p>Cellular service</p>
        </div>
        <div class="lock">
          <div class="image">
            <img src="@/assets/images/equipment/equip5.png" />
          </div>
          <p class="text">Haroware OEM Certification</p>
        </div>
        <div class="lock">
          <div class="image">
            <img src="@/assets/images/equipment/equip6.png" />
          </div>
          <p>Data Wipe & Restore</p>
        </div>
      </div>
    </div>
    <div class="serve">
      <div class="serve1">
        <div class="left">
          <p>Compatibility</p>
          <p class="blur">Mac / iOS / Android</p>
        </div>
        <div class="right">
          <div class="image">
            <img src="@/assets/images/equipment/serve1.png" />
          </div>
          <p class="bold">
            The K-Check App runs natively on any Mac, or Windows PC.
          </p>
          <p>
            K-Check can scan any Apple, or Android Device produced in the last
            10 years. Our team is constantly developing updates for new devices
            as they are released.
          </p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="serve2">
        <div class="left">
          <p>Batch Processing & Automation</p>
        </div>
        <div class="right">
          <div class="image">
            <img src="@/assets/images/equipment/serve2.png" />
          </div>
          <p>
            K-Check was desigined with high volume processors and revserse
            logistics companies in mind. Our clients regularly process hundreds
            of thousands of units per month using our customized solutions.
          </p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="serve3">
        <div class="left">
          <p>Data Scrubbing</p>
        </div>
        <div class="right">
          <div class="image">
            <img src="@/assets/images/equipment/serve3.png" />
          </div>
          <p>
            K-Check Pro Version 1 has undergone product claims test by ADISA.
            ADISA sets the standard for data disposal services.
          </p>
        </div>
      </div>
      <div class="divider"></div>

      <div class="serve4">
        <div class="left">
          <p>Cloud Based Results</p>
        </div>
        <div class="right">
          <div class="image">
            <img src="@/assets/images/equipment/serve4.png" />
          </div>
          <p>
            The results for every device you scan are stored in your cloud
            account, which you can access through our easy- to-use dashboard
          </p>
        </div>
      </div>

      <div class="divider"></div>

      <div class="serve5">
        <div class="left">
          <div class="image">
            <img src="@/assets/images/equipment/serve5.png" />
          </div>
        </div>
        <div class="right">
          <p class="bold">One-on-One Mentoring +24+7</p>
          <p>
            Our support team is here 24 hours a day to help you get the job
            done.
          </p>
          <router-link to="/contact">
            <div class="button">
              <img src="@/assets/images/equipment/button.png" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="divider"></div>

      <div class="serve6">
        <div class="left">
          <p>Non OEM / Replacement Parts Detection</p>
        </div>
        <div class="right">
          <p>
            K-Check automatically performs a hardware diagnostic test to not
            only test the functionality but the ORIGINALITY of the hardware
            contined inside the device, alerting you when som- ething has been
            replaced withan aftermarket part.
          </p>
        </div>
      </div>
      <div class="divider"></div>

      <div class="serve7">
        <div class="left">
          <p>Complete diagnosis</p>
        </div>
        <div class="right">
          <p>
            There are thousands of components in a modern smartphone or tablet.
            K-Check automated device diagnostics scans the functionality,
            capacity and condition of all of them.There are 41 test sites.
          </p>
          <div class="detail">
            <div class="complete-left">
              <ul>
                <li>Wifi</li>
                <li>Bluetooth</li>
                <li>GPS</li>
                <li>Read Sim</li>
                <li>Carrier Lock</li>
                <li>Magnetomeyer</li>
                <li>Accelerometer</li>
                <li>Gyroscope</li>
                <li>Barometer</li>
                <li>NFC</li>
                <li>Video Microphone</li>
                <li>Front Microphone</li>
                <li>Bottom Microphone</li>
                <li>Mic Ear Speaker</li>
                <li>Loud Speaker</li>
                <li>Face ID</li>
                <li>Finger Touch</li>
                <li>Battery info</li>
                <li>USB Charger</li>
                <li>Wireless Charger</li>
                <li>Flash</li>
              </ul>
            </div>
            <div class="complete-right">
              <ul>
                <li>Headset Port</li>
                <li>Headset-Left</li>
                <li>Headset-Right</li>
                <li>Display</li>
                <li>Multi Touch</li>
                <li>Touch Panel</li>
                <li>3D Touch</li>
                <li>Light Sensor</li>
                <li>Proximity Sensor</li>
                <li></li>
                <li>True Tone</li>
                <li>Power Button</li>
                <li>Home Button</li>
                <li>Volume Down Button</li>
                <li>Volume Up Button</li>
                <li>Slience Button</li>
                <li>Vibration</li>
                <li>Wide Camera</li>
                <li>True Depth Camera</li>
                <li>Rear Camera</li>
                <li>Front Camera</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>

      <div class="serve8">
        <div class="left">
          <p>History and Reality</p>
        </div>
        <div class="right">
          <div class="image">
            <img src="@/assets/images/equipment/serve6.png" />
          </div>
          <p>
            K-Check's History & Identity reeport gives you piece of mind.Using
            the device's unique IMEl, K-Check can confirm thee device's
            authenticity. In addition, an IMEl check is performed ensuring a
            device has not been lost/stolen, hacked or jailbroken.
          </p>
        </div>
      </div>
      <div class="divider"></div>

      <div class="serve9">
        <div class="left">
          <p>Increase resale value</p>
        </div>
        <div class="right">
          <p>K-Check is the most trusted name in device certification.</p>
          <p>
            K-Check Certified Devices sell faster, and for more money. Our easy
            to print labels integrate with any inventory system, and unique
            certification reports are generated for every device you scan.
          </p>
          <p>
            We also have made it easy to embed K-Check reports on your website
            as well as online listings like eBay.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref, onMounted } from "vue";
import BlackBanner from "@/components/common/BlackBanner.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Equipment",
  components: {
    BlackBanner,
  },
  setup() {
    const route = useRoute();
    const search = ref("");
    watch(
      route,
      () => {
        search.value = route.fullPath.split("#")[1];
        switch (search.value) {
          case "Features":
            document.querySelector(".content").scrollIntoView(true);
            break;
          case "Compatibility":
            document.querySelector(".serve1").scrollIntoView(true);
            break;
          case "Automation":
            document.querySelector(".serve2").scrollIntoView(true);
            break;
          case "Data":
            document.querySelector(".serve3").scrollIntoView(true);
            break;
          case "Cloud":
            document.querySelector(".serve4").scrollIntoView(true);
            break;
          case "Support":
            document.querySelector(".serve5").scrollIntoView(true);
            break;
          case "Detection":
            document.querySelector(".serve6").scrollIntoView(true);
            break;
          case "Diagnosis":
            document.querySelector(".serve7").scrollIntoView(true);
            break;
          case "History":
            document.querySelector(".serve8").scrollIntoView(true);
            break;
          case "Resale":
            document.querySelector(".serve9").scrollIntoView(true);
            break;
          default:
            break;
        }
      }
      // { immediate: true }
    );

    onMounted(() => {
      switch (route.fullPath.split("#")[1]) {
        case "Features":
          document.querySelector(".content").scrollIntoView(true);
          break;
        case "Compatibility":
          document.querySelector(".serve1").scrollIntoView(true);
          break;
        case "Automation":
          document.querySelector(".serve2").scrollIntoView(true);
          break;
        case "Data":
          document.querySelector(".serve3").scrollIntoView(true);
          break;
        case "Cloud":
          document.querySelector(".serve4").scrollIntoView(true);
          break;
        case "Support":
          document.querySelector(".serve5").scrollIntoView(true);
          break;
        case "Detection":
          document.querySelector(".serve6").scrollIntoView(true);
          break;
        case "Diagnosis":
          document.querySelector(".serve7").scrollIntoView(true);
          break;
        case "History":
          document.querySelector(".serve8").scrollIntoView(true);
          break;
        case "Resale":
          document.querySelector(".serve9").scrollIntoView(true);
          break;
        default:
          break;
      }
    });

    return {
      search,
    };
  },
});
</script>

<style lang="less" scoped>
.equipment {
  .header {
    display: flex;
    justify-content: center;
    height: 715px;
    background: url("~@/assets/images/equipment/equipBg.png");
    background-size: cover;
    .wrap {
      display: flex;
      margin: 0 auto;
      .image {
        width: 217px;
        height: 438px;
        // margin-right: 154px;
        margin-top: 70px;
        margin-left: 378px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        width: 450px;
        height: 148px;
        font-size: 36px;
        font-family: Abhaya Libre SemiBold;
        font-weight: 400;
        text-align: LEFT;
        color: #000000;
        line-height: 36px;
        margin-top: 221px;
        margin-left: 154px;
      }
    }
  }
  .content {
    height: 664px;
    background: rgba(42, 62, 113, 0.02);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .top,
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      .lock {
        width: 200px;
        height: 200px;
        border-radius: 30px;
        border: 4px solid #cbcaca;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // cursor: pointer;
        margin-left: 107px;
        // margin-bottom: 104px;

        .image {
          width: 50px;
          height: 50px;
          margin: 0 auto;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          margin-top: 25px;
        }
        .text {
          width: 70px;
          margin: 0 auto;
          margin-bottom: 16px;
        }
        .disabled {
          display: none;
        }
        &:hover {
          transform: scale(1.5);
          transition: all 0.5s ease-in-out;
          background: #dfe7fd;
          border-radius: 30px;
          .diagnose {
            margin: 0;
          }
          .disabled {
            display: block;
          }
        }
      }
    }
    // .bottom {
    //   display: flex;
    //   justify-content: center;
    //   .image {
    //     width: 200px;
    //     height: 200px;
    //     margin-left: 107px;
    //     &:first-of-type {
    //       margin-left: 0px;
    //     }
    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }
  }
  .serve {
    padding-left: 352px;
    padding-right: 281px;
    .divider {
      height: 1px;
      background-color: #696969;
      margin-top: 80px;
      margin-bottom: 69px;
    }
    .left {
      width: 432px;
      p {
        font-size: 36px;
        font-family: Abhaya Libre ExtraBold;
        font-weight: 400;
        text-align: LEFT;
        color: #000000;
        line-height: 27px;
      }
    }
    .right {
      width: 642px;
      p {
        font-size: 24px;
        line-height: 34px;
        font-weight: 400;
      }
    }
    .serve1 {
      display: flex;
      margin-top: 125px;
      justify-content: space-evenly;
      text-align: left;
      .left {
        p {
          margin-bottom: 0px;
        }
      }
      .blur {
        font-size: 24px;
        font-family: Abhaya Libre ExtraBold;
        line-height: 27px;
        text-align: left;
        color: #a7a7a7;
        width: 250px;
      }
      .right {
        display: flex;
        flex-direction: column;
        .image {
          width: 224px;
          height: 170px;
          margin: 115px auto 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .bold {
          font-weight: bold;
        }
      }
    }

    .serve2 {
      display: flex;
      justify-content: space-evenly;
      text-align: left;
      .right {
        display: flex;
        flex-direction: column;
        .image {
          width: 454px;
          height: 142px;
          margin-top: 109px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .serve3 {
      display: flex;
      justify-content: space-evenly;
      text-align: left;
      .right {
        display: flex;
        flex-direction: column;
        .image {
          width: 144px;
          height: 138px;
          margin-top: 109px;
          margin-left: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .serve4 {
      display: flex;
      justify-content: space-evenly;
      .right {
        width: 642px;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        .image {
          width: 152px;
          height: 306px;
          margin-top: 109px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .serve5 {
      display: flex;
      justify-content: space-evenly;
      .left {
        .image {
          width: 432px;
          height: 410px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        text-align: left;
        .bold {
          font-weight: bold;
        }
        .button {
          width: 111px;
          height: 46px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .serve6 {
      display: flex;
      justify-content: space-evenly;
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        margin-top: 109px;
      }
    }

    .serve7 {
      display: flex;
      justify-content: space-evenly;
      .right {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 109px;
        .detail {
          display: flex;
          ul {
            padding: 0;
            li {
              list-style: none;
              line-height: 24px;
              margin-bottom: 2px;
            }
          }
          .complete-right {
            margin-left: 103px;
          }
        }
      }
    }

    .serve8 {
      display: flex;
      justify-content: space-evenly;
      .right {
        display: flex;
        flex-direction: column;
        //   margin: 0 auto;
        align-items: center;
        text-align: left;
        .image {
          width: 470px;
          height: 261px;
          margin-top: 109px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .serve9 {
      display: flex;
      margin-bottom: 167px;
      justify-content: space-evenly;
      .right {
        margin-top: 109px;
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }
  }
}
</style>
